import client from './client'

export function listCountries () {
  return client.get('/country/')
}

export function listCountryDepartments (countryId = 1) {
  return client.get(`/country/${countryId}/department/`)
}

export function listCountryDepartmentCities (countryId, departmentId) {
  return client.get(`/country/${countryId}/department/${departmentId}/city/`)
}
