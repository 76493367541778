<template>
  <header id="site-header"
          class="header-menu d-flex justify-content-between">
    <div class="logo">
      <router-link :to="{ name: 'authorizations.index' }">
        <img src="../assets/images/logo.png"
             alt="Premat">
      </router-link>
    </div>
    <div class="title">
      <h1>{{ title }}</h1>
      <h2 v-if="showSubtitle" class="subtitle">{{ subtitle }}</h2>
    </div>
    <div class="navigation">
      <div class="dropdown">
        <a class="btn btn-secondary dropdown-toggle"
           href="#"
           role="button"
           id="dropdownMenuLink"
           data-toggle="dropdown"
           aria-haspopup="true"
           aria-expanded="false">
          {{ username }} <i class="icon-down-mini"></i>
        </a>

        <div class="dropdown-menu"
             aria-labelledby="dropdownMenuLink">
          <a v-if="showUsers" class="dropdown-item"
             @click="() => { $router.push('/users'); }">
            Utilisateurs
          </a>
          <a @click.prevent="userLogout"
             class="dropdown-item"
             href="#">
            Se déconnecter
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import user from '@/store/user'

export default {
  computed: {
    ...mapState({
      authorization: state => state.authorization.authorization
    }),
    ...mapGetters({
      username: 'user/username'
    }),
    title () {
      if (this.isAuthorizationShowPage && this.authorization) {
        return `Autorisation n°${this.authorization.num} - ${this.authorization.num_authorization_on_pdf} - ${this.$route.params.id}`
      }
      return this.$route.meta.title || null
    },
    subtitle () {
      if (this.isAuthorizationShowPage) {
        return `${this.authorization.city_start} - ${this.authorization.city_end}`
      }
      return null
    },
    showSubtitle () {
      return this.authorization && this.isAuthorizationShowPage
    },
    isAuthorizationShowPage () {
      return this.$route.name === 'authorizations.show'
    },
    showUsers () {
      return user.state.user
        ? user.state.user.user.access === 'admin' || user.state.user.user.access === 'manager'
        : false
    }
  },
  methods: {
    ...mapActions({
      logout: 'user/logout'
    }),
    userLogout () {
      this.logout()
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(() => this.$router.push({ name: 'login' }))
    }
  }
}
</script>

<style scoped lang="scss">
  .header-menu {
    // padding: 32px;
    padding: 10px;

    @media (max-width: $breakpoint-sm) {
      padding: 20px 15px;
    }
  }

  .logo img {
    width: 192px;
    height: auto;

    @media (max-width: $breakpoint-sm) {
      width: 150px;
    }
  }

  .title {
    text-align: center;

    @media (max-width: $breakpoint-sm) {
      width: 50%;
    }

    h1 {
      font-weight: normal;

      @media (max-width: $breakpoint-sm) {
        line-height: 1;
      }
    }
  }

  .subtitle {
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    @media (max-width: $breakpoint-sm) {
      line-height: 1;
    }
  }

  .navigation {
    width: 192px;
    text-align: right;

    @media (max-width: $breakpoint-sm) {
      width: 150px;
    }
  }

  .navigation .btn {
    text-transform: none;
    background: none !important;
    border: none;
    color: $gray-text;
    font-size: 14px;
    outline: none !important;

    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
    }

    &:hover,
    &:active,
    &:focus {
      color: $gray-text;
      background: none !important;
      border: none !important;
      outline: none !important;
    }
  }

  .navigation .dropdown.show > .btn-secondary.dropdown-toggle {
    color: $gray-text;
    outline: none !important;
  }

  .navigation .dropdown-toggle {
    &:after {
      display: none;
    }

    &:hover,
    &:active,
    &:focus {
      color: $gray-text !important;
      border-color: transparent !important;
      outline-color: transparent !important;
      box-shadow: none !important;
    }
  }

  .navigation i {
    color: $red;
    margin-left: 3px;
    font-size: 12px;

    @media (max-width: $breakpoint-sm) {
      font-size: 10px;
    }
  }

  .dropdown-menu {
    @media (max-width: $breakpoint-sm) {
      padding-top: 0;
      padding-bottom: 0;
      min-width: auto;
    }
  }

  .dropdown-item {
    @media (max-width: $breakpoint-sm) {
      padding: 3px 10px;
    }
  }
</style>
