import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/user'
import authorization from '@/store/authorization'

Vue.use(Vuex)

export default new Vuex.Store({
  // Whenever vuex state is mutated outside of mutation handlers, an error will
  // be thrown. Turn it off in production to avoid the performance cost.
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    user,
    authorization
  }
})
