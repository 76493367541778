import client from './client'
import qs from 'qs'

export function login (username, password) {
  return client.put(`/user/${username}/sign_in/`, qs.stringify({ pwd: password }))
}

export function logout () {
  return client.put('/user/sign_out/')
}

export function register (username, email, password) {
  return client.post(`/user/${username}/`, qs.stringify({
    login: username,
    mail: email,
    pwd: password
  }))
}

export function listUsers () {
  return client.get('/user/')
}

export function getUser (id) {
  return client.get(`/user/${id}/`)
}

export function deleteUser (id) {
  return client.delete(`/user/${id}/`)
}
