<template>
  <div :class="formGroupClasses">
    <label v-if="label"
           :for="id">
      {{ label }}
    </label>
    <div v-if="type === 'password'"
         class="input-group">
      <input @input="updateValue($event.target.value)"
             @keyup.enter="forceSubmit"
             :type="passwordInputType"
             :id="id"
             :name="name"
             :value="value"
             :placeholder="placeholder"
             :disabled="disabled"
             class="form-control">
      <div class="input-group-append">
        <div @click="switchPasswordVisibility"
             class="inner">
          <i class="icon-invisible"></i>
        </div>
      </div>
    </div>
    <input v-else
           @input="updateValue($event.target.value)"
           @keyup.enter="forceSubmit"
           :type="type"
           :id="id"
           :name="name"
           :value="value"
           :placeholder="placeholder"
           :disabled="disabled"
           class="form-control">
    <div v-if="error"
         class="help-block">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    value: {
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      passwordVisible: false
    }
  },
  computed: {
    formGroupClasses () {
      return {
        'form-group': true,
        'has-error': !!this.error
      }
    },
    passwordInputType () {
      return this.passwordVisible ? 'text' : 'password'
    }
  },
  methods: {
    switchPasswordVisibility () {
      this.passwordVisible = !this.passwordVisible
    },
    updateValue (value) {
      this.$emit('input', value)
    },
    forceSubmit (value) {
      if (!value) return
      this.$emit('force-submit')
    }
  }
}
</script>

<style scoped lang="scss">
  .input-group-append:hover {
    cursor: pointer;
  }
</style>
