import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/authorizations'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginPage.vue'),
    meta: {
      requiresAuth: false,
      title: 'Login'
    }
  },
  {
    path: '/authorizations',
    name: 'authorizations.index',
    component: () => import(/* webpackChunkName: "authorizations.index" */ '../views/AuthorizationsIndexPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Tableau de bord'
    }
  },
  {
    path: '/users',
    name: 'users.index',
    component: () => import(/* webpackChunkName: "authorizations.index" */ '../views/UserAdminPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Utilisateurs'
    }
  },
  {
    path: '/authorizations/create',
    name: 'authorizations.create',
    component: () => import(/* webpackChunkName: "authorizations.create" */ '../views/AuthorizationsCreatePage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Créer une autorisation'
    }
  },
  {
    path: '/authorizations/visualize',
    name: 'authorizations.visualize',
    component: () => import(/* webpackChunkName: "authorizations.visualize" */ '../views/AuthorizationsVisualizePage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Visualiser des autorisations'
    }
  },
  {
    path: '/authorizations/:id',
    name: 'authorizations.show',
    component: () => import(/* webpackChunkName: "authorizations.show" */ '../views/AuthorizationsShowPage.vue'),
    meta: {
      requiresAuth: true,
      title: ''
    }
  },
  {
    path: '/authorizations/:id/edit',
    name: 'authorizations.edit',
    component: () => import(/* webpackChunkName: "authorizations.edit" */ '../views/AuthorizationsEditPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Éditer l\'autorisation'
    }
  },
  {
    path: '/authorizations/:id/steps/create',
    name: 'authorizations.steps.create',
    component: () => import(/* webpackChunkName: "authorizations.steps.create" */ '../views/AuthorizationsStepsCreatePage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Tracer l\'autorisation'
    }
  },
  {
    path: '/authorizations/:id/steps/:num_step(\\d+)/create-next',
    name: 'authorizations.steps.create-next',
    component: () => import(/* webpackChunkName: "authorizations.steps.create-next" */ '../views/AuthorizationsStepsCreateNextPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Tracer l\'autorisation'
    }
  },
  {
    path: '/authorizations/:id/steps/:num_step(\\d+)',
    name: 'authorizations.steps.show',
    component: () => import(/* webpackChunkName: "authorizations.steps.show" */ '../views/AuthorizationsStepsShowPage'),
    meta: {
      requiresAuth: true,
      title: 'Visualiser l\'itinéraire complet'
    }
  },
  {
    path: '/authorizations/:id/steps/:num_step(\\d+)/edit',
    name: 'authorizations.steps.edit',
    component: () => import(/* webpackChunkName: "authorizations.steps.edit" */ '../views/AuthorizationsStepsEditPage'),
    meta: {
      requiresAuth: true,
      title: 'Tracer l\'autorisation'
    }
  },
  {
    path: '/authorizations/:id/steps',
    name: 'authorizations.steps.index',
    component: () => import(/* webpackChunkName: "authorizations.steps.index" */ '../views/AuthorizationsStepsIndexPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Tracer l\'autorisation'
    }
  },
  {
    path: '/authorizations/:id/validate',
    name: 'authorizations.validate',
    component: () => import(/* webpackChunkName: "authorizations.validate" */ '../views/AuthorizationsValidatePage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Valider l\'autorisation'
    }
  },
  {
    path: '/paths',
    name: 'paths',
    component: () => import(/* webpackChunkName: "paths" */ '../views/PathsPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Demander des itinéraires'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFoundPage.vue'),
    meta: {
      requiresAuth: false,
      title: '404'
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // the requested route requires authentication
    if (localStorage.getItem('user') === null) {
      // no user is logged in, we redirect to the login page
      next({ name: 'login' })
    } else {
      // a user is already logged in, we can route him to the requested page
      next()
    }
  } else {
    // the requested route does not require authentication, we can route the
    // user to the requested page
    next()
  }
})

export default router
