import client from './client'
import { saveAs } from 'file-saver'
import qs from 'qs'

export function listAuthorizations (page, limit, startCity, endCity, tonnage) {
  return client.get('/authorization/', {
    params: {
      page,
      limit,
      city_start: startCity,
      city_end: endCity,
      tonnage
    }
  })
}

export function getAuthorization (authorizationId) {
  return client.get(`/authorization/${authorizationId}/`)
}

export function extractAuthorizationGeojson (authorization, way) {
  const geojsonRaw = way === 0 ? authorization.geojson_way_0
    : authorization.geojson_way_1
  if (geojsonRaw === null) {
    console.log('Authorization ' + authorization.id.toString()
                + ' has no geojson')
    return null
  }

  let geojsonStr
  if (geojsonRaw.length > 0 && geojsonRaw[0] == '"'
      && geojsonRaw[geojsonRaw.length - 1] == '"') {
    geojsonStr = geojsonRaw.substring(1, geojsonRaw.length - 1)
  } else {
    geojsonStr = geojsonRaw
  }

  geojsonStr = geojsonStr.replace(/\{'/g, '{"')
  geojsonStr = geojsonStr.replace(/'\}/g, '"}')
  geojsonStr = geojsonStr.replace(/\['/g, '["')
  geojsonStr = geojsonStr.replace(/'\]/g, '"]')
  geojsonStr = geojsonStr.replace(/, '/g, ', "')
  geojsonStr = geojsonStr.replace(/',/g, '",')
  geojsonStr = geojsonStr.replace(/':/g, '":')
  geojsonStr = geojsonStr.replace(/: '/g, ': "')
  geojsonStr = geojsonStr.replace(/True/g, '1')
  geojsonStr = geojsonStr.replace(/False/g, '0')

  let geojson
  try {
    geojson = JSON.parse(geojsonStr)
    geojson.features.forEach(feature => {
      feature.properties.authorization_id = authorization.id.toString()
    })
  } catch (error) {
    console.error('ERROR in authorization.extractAuthorizationGeojson')
    console.error('EXCEPTION:', error)
    console.error('DATA: geojsonStr =', geojsonStr)
    console.error('DATA: geojson =', geojson)
  }

  return geojson
}

export function createAuthorization (data) {
  return client.post('/authorization/', data, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  })
}

export function updateAuthorization (authorizationId, data) {
  return client.post(`/authorization/${authorizationId}/`, data, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  })
}

export function deleteAuthorization (authorizationId) {
  return client.delete(`/authorization/${authorizationId}/_delete_do_no_use/`)
}

export function activateAuthorization (authorizationId) {
  return client.put(`/authorization/${authorizationId}/activate/`)
}

export function deactivateAuthorization (authorizationId) {
  return client.put(`/authorization/${authorizationId}/deactivate/`)
}

export function getAuthorizationPdf (authorization) {
  return new Promise((resolve, reject) => {
    client
      .get(`/authorization/${authorization.id}/pdf/`, {
        responseType: 'blob'
      })
      .then(response => {
        saveAs(response.data, `${authorization.num_authorization_on_pdf}.pdf`)
        resolve(response)
      })
      .catch(error => {
        error.response.data.text().then(text => {
          alert('Impossible de récupérer le PDF : ' + JSON.parse(text).message)
        })
        reject(error)
      })
  })
}

export function getAuthorizationsStatistics () {
  return client.get('/authorization/stats/')
}

export function getAuthorizationSteps (authorizationId) {
  return client.get(`/authorization/${authorizationId}/step/`)
}

export function createAuthorizationSteps (authorizationId, data) {
  const steps = []
  // we allow these keys to contain an empty value
  const canBeNull = ['name', 'comment', 'page_number']
  data.forEach(step => {
    for (const key in step) {
      if (!canBeNull.includes(key)
        && (step[key] === null || step[key] === undefined || step[key] === '' || key === 'draggable')) {
        delete step[key]
      }
    }
    steps.push(step)
  })
  let formAsString = 'replace=1' + '&'
  steps.forEach(step => {
    formAsString += qs.stringify(step) + '&'
  })
  return client.post(`/authorization/${authorizationId}/step/`, formAsString, {
    headers: { 'X-No-Transform': '1' }
  })
}

export function deleteAuthorizationStep (authorizationId, stepId, way = 0) {
  return client.delete(`/authorization/${authorizationId}/step/${stepId}/${way}/`)
}

export function updateAuthorizationRenewal (authorizationId, data) {
  return client.put(`/authorization/${authorizationId}/renewal/`, data)
}

export function duplicateReverseAuthorization (authorizationId) {
  return client.put(`/authorization/${authorizationId}/duplicate_and_reverse/`)
}
