import client from './client'

export function getDirections (authorization_id, way, coordinates, use_ors) {
  let latitudesForm = ''
  let longitudesForm = ''
  let useORSForm = ''
  for (var i = 0; i < coordinates.length; i++) {
    latitudesForm += 'latitude=' + coordinates[i][1] + '&'
    longitudesForm += 'longitude=' + coordinates[i][0] + '&'
    useORSForm += 'use_ors=' + use_ors[i] + '&'
  }
  const latitudesLongitudesForm = latitudesForm + longitudesForm + useORSForm
  return client.get('/ors/directions/?' + 'authorization_id=' + authorization_id + '&' + 'way=' + way + '&' + latitudesLongitudesForm)
}
