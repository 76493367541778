
<template>
    <div class>
        <label v-if="label">
            {{ label }}
        </label>
        <v-select
            v-model="localValue"
            :class="`premat-input-select ${disabled ? 'premat-input-select-disabled' :''}`"
            :placeholder="value ? value.name : placeholder"
            :clearable="Clearable"
            append-to-body
            searchable
            filtrable
            :options="localItems"
            :loading="requestLoading"
            :multiple="multiple"
            :close-on-select="closeOnSelect"
            :disabled="disabled"
            :query="query"
            :item-value="itemValue"
            :getOptionLabel="itemText"
            :outlined="outlined"
            :label="noLabelField ? '':label"
            :reduce="itemValue ? x => x[itemValue] : x => x"
            :selectable="selectable"
        >
            <template v-if="hasPrependItemScopedSlot" v-slot:prepend-item>
                <slot name="prepend-item" />
            </template>
            <template v-if="hasNoDataScopedSlot" v-slot:no-data>
                <slot name="no-data" />
            </template>
            <template v-if="hasLabelScopedSlot" v-slot:label>
                <slot name="label" />
            </template>
            <template v-if="hasItemScopedSlot" v-slot:item="{ item }">
                <slot name="item" :item="item" />
            </template>
            <template
                v-if="hasSelectionScopedSlot && !hideSelection"
                v-slot:selection="{ item, index }"
            >
                <slot name="selection" :item="item" :index="index" />
            </template>
        </v-select>
    </div>
</template>

<script>
import _ from 'lodash'
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'

export default {
  name: 'FormInputSelect',
  components: {
    vSelect
  },
  inheritAttrs: false,
  props: {
    value: { type: null, required: true },
    addSlot: { type: Boolean, default: false },
    placeholder: { type: String, required: false, default: '' },
    label: { type: String, default: null },
    items: { type: Array, default: () => [] },
    endpoint: { type: String, default: '' },
    autocomplete: { type: Boolean, default: false },
    rules: { type: Array, required: false, default: () => [] },
    query: {
      type: Object,
      required: false,
      default: () => {
        return { $then: [{ 'Name.sort': 1 }] }
      }
    },
    useValue: { type: Boolean, default: false },
    onEditQuery: { type: Function, required: false, default: null },
    minimumCharacters: { type: Number, required: false, default: 0 },
    getItemsRules: { type: Array, required: false, default: () => [] },
    loading: { type: Boolean, required: false, default: false },
    clearable: { type: Boolean, default: false },
    hideSelection: { type: Boolean, required: false, default: false },
    multiple: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    itemValue: { type: String, required: false, default: null },
    noLabelField: { type: Boolean, required: false, default: false },
    outlined: { type: Boolean, required: false, default: false },
    wait: { type: Number, required: false, default: 1000 },
    setUndefinedIfNoItems: { type: Boolean, required: false, default: false },
    returnObject: { type: Boolean, required: false, default: false },
    multiple: { type: Boolean, default: false },
    min: { type: Number, default: undefined },
    max: { type: Number, default: undefined },
    closeOnSelect: { type: Boolean, default: true },
    itemText: { type: Function, default: x => null },
    getOptionKey: { type: Function, default: x => x.name },
    selectable: { type: Function, default: x => true },
    required: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    readonly: { type: Boolean, default: false }
  },
  data () {
    const localGetItemsRules = _.cloneDeep(this.getItemsRules)
    if (this.minimumCharacters > 0) {
      localGetItemsRules.push(v => !!v && v.length >= this.minimumCharacters)
    }
    return {
      localEndpoint: this.endpoint,
      localGetItemsRules,
      localItems: _.cloneDeep(this.items),
      localValue: _.cloneDeep(this.value),
      requestLoading: false,
      search: null
    }
  },
  computed: {
    hasNoDataScopedSlot () {
      return 'no-data' in this.$scopedSlots
    },
    hasLabelScopedSlot () {
      return 'label' in this.$scopedSlots
    },
    hasPrependItemScopedSlot () {
      return 'prepend-item' in this.$scopedSlots
    },
    hasItemScopedSlot () {
      return 'item' in this.$scopedSlots
    },
    hasSelectionScopedSlot () {
      return 'selection' in this.$scopedSlots
    },
    selectHint () {
      return this.hasMinimumCharacters
        ? [`At least ${this.minimumCharacters} characters`]
        : []
    },
    requestId () {
      return this.$uid()
    },
    debouncedGetItems () {
      return _.debounce(this.getItems, 400)
    },
    Clearable () {
      return this.clearable && !this.requestLoading
    }
  },
  watch: {
    endpoint () {
      this.localEndpoint = this.endpoint
      this.debouncedGetItems()
    },
    items () {
      this.localItems = _.cloneDeep(this.items)
    },
    localItems () {
      if (this.setUndefinedIfNoItems && this.localItems.length === 0) {
        this.value = undefined
      }
    },
    query (val) {
      val ? this.debouncedGetItems(val) : this.debouncedGetItems()
    },
    search (val) {
      this.debouncedGetItems(val)
    },
    value () {
      this.localValue = _.cloneDeep(this.value)
    },
    localValue () {
      this.$emit('input', this.localValue)
    }
  },
  created () {
    this.debouncedGetItems()
  },
  methods: {
    makeQuery (val) {
      if (this.onEditQuery && val !== null) {
        return _.merge(this.query, this.onEditQuery(val))
      } else {
        return this.query
      }
    },
    isGetItemsRulesValid (val) {
      return _.every(this.localGetItemsRules, r => {
        return r(val) === true
      })
    },
    getItems (val) {
      if (this.isGetItemsRulesValid(val)) {
        if (this.localEndpoint) {
          this.requestLoading = true
          if (this.useValue) {
            this.$api(this.requestId, `${this.localEndpoint}/all`, 'get', {
              value: val
            })
              .then(res => {
                if (_.isArray(res.data.value)) {
                  this.localItems = res.data.value
                  this.requestLoading = false
                } else {
                  throw new Error('premat-input-select: getItems')
                }
              })
            // Catch cancellation
              .catch(this.$handleErrors())
          } else {
            this.$api(this.requestId, `${this.localEndpoint}/all`, 'get', {
              q: this.makeQuery(val)
            })
              .then(res => {
                if (_.isArray(res.data.value)) {
                  this.localItems = res.data.value
                  this.requestLoading = false
                } else {
                  throw new Error('premat-input-select: getItems')
                }
              })
            // Catch cancellation
              .catch(this.$handleErrors())
          }
        }
      }
    },
    clearAndDeactivateFocus () {
      this.$emit('clear')
    }
  }
}
</script>

<style>
.label-input-select {
  font-family: Inter, sans-serif;
  font-size: 15px;
}

.vs__dropdown-toggle {
  height: 40px;
  border: 1px solid #ececec !important;
  font-family: Inter, sans-serif;
  font-size: 15px;
  color: #000000;
}

.premat-input-select .vs__selected {
    color: #000000 ! important;
}

.vs__dropdown-menu, .td .vs__dropdown-menu {
    z-index: 20000 !important;
}

.premat-input-select .vs__dropdown-toggle,
.premat-input-select .vs__dropdown-menu {
    min-width:50px;
    max-height:42px;
    width: 100%;
    background: white !important;
    color: #000000 ! important;
    border: 1px solid #f9124f ! important;
    border-radius: 3px;
    text-overflow: ellipsis;
}

.missing-required-value .premat-input-select .vs__dropdown-toggle{
  background: #FF9999 !important;
}

.premat-input-select .vs__search::placeholder{
  min-width: 200px;
  width: auto;
  overflow-x: auto;
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: grey;
}

.premat-input-select .vs__selected-options {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    flex-wrap: nowrap;
    padding: 0 2px;
    position: relative;
}

.premat-input-select .vs__dropdown-menu li:hover {
  background-color: #003684 !important;
}
.premat-input-select .vs__dropdown-menu li::before {
  background-color: #003684 !important;
}

.vs__dropdown-option {
  /*padding-left: 30px !important;*/
  margin-left: -15px;
}

.premat-input-select .vs_loading, .premat-input-select .vs__spinner{
    min-height: 25px;
    min-width: 25px;
}

.premat-input-select
  .v-input
  .v-text-field--solo
  .v-select.v-select--chips
  .v-select__selections
  .v-chip--select {
  font-size: 11px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.vs__open-indicator,
.vs__clear {
    fill: red;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
    cursor: not-allowed;
    fill: #dddddd !important;
}

.premat-input-select.premat-input-select-disabled  .vs__dropdown-toggle{
  background: #dddddd !important;
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15)
}

.vs__dropdown-option--disabled {
    display: none;
}

</style>
