import Vue from 'vue'
import App from '@/App.vue'
// import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import 'popper.js'
import 'jquery'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'
import '@/assets/sass/app.scss'
import { Icon } from 'leaflet'
import { ServerTable, ClientTable } from 'vue-tables-2'
import { debounce } from 'debounce'
import UserFormDialog from '@/components/UserFormDialog'
import VuejsDialog from 'vuejs-dialog'
import Snackbar from 'vuejs-snackbar'

Vue.component('snackbar', Snackbar)

Vue.use(VuejsDialog)
Vue.dialog.registerComponent('user-form-dialog', UserFormDialog)

Vue.use(ClientTable)

Vue.config.productionTip = false

Vue.use(ServerTable)

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconSize: [16, 16],
  iconAnchor: [16, 16],
  iconUrl: require('@/assets/images/step-point.png')
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

const listenToResize = function () {
  const windowHeight = window.innerHeight
  const siteHeader = document.getElementById('site-header')
  const splitPage = document.getElementById('split-page')
  if (siteHeader && splitPage) {
    const headerHeight = siteHeader.offsetHeight
    let mapHeight = windowHeight - headerHeight
    const splitPageHeader = document.getElementById('split-page-header')
    if (splitPageHeader) {
      mapHeight = mapHeight - splitPageHeader.offsetHeight
    }
    splitPage.style.height = `${mapHeight}px`
  }
}

document.addEventListener('DOMContentLoaded', () => {
  // we listen to window resize event and apply style on several elements to
  // have a map of 100% height
  window.addEventListener('resize', debounce(listenToResize, 60))
})
