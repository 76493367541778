<template>
  <div id="app">
    <SiteHeader v-if="showHeaderMenu" class="site-header"/>
    <router-view class="container-fluid"/>
  </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader'

export default {
  components: {
    SiteHeader
  },
  computed: {
    showHeaderMenu () {
      return !['login', '404', 'wildcard'].includes(this.$route.name)
    }
  }
}
</script>

<style language="scss">
  #app {
    display: flex;
    flex-direction: column;
  }

  .site-header {
    flex: 0 1 auto;
  }

  .site-content {
    display: flex;
    flex: 1 1 auto;
  }
</style>
