import client from './client'

export function autocompleteCity (text, limit = 0) {
  return new Promise((resolve, reject) => {
    client.get('/city/find_startswith/', {
      params: {
        name: text,
        limit
      }
    })
      .then(response1 => {
        const cities = response1.data.cities
        client.get('/country/')
          .then(response2 => {
            const countries = response2.data.countries
            cities.forEach((city, index) => {
              const country = countries.find((country) => { return country.id === city.id_country })
              city.country_name = country.name
            })
            resolve(cities)
          })
          .catch(error => reject(error))
      })
      .catch(error => reject(error))
  })
}

export function findOneCity (text) {
  const text_splitted = text.split(', ')
  return new Promise((resolve, reject) => {
    client.get('/city/find/', {
      params: { name: text_splitted[0] }
    })
      .then(response => {
        const cities = response.data.cities
        let r_city = null
        cities.forEach((city, index) => {
          // console.log('findOneCity text_splitted = ', text_splitted)
          // console.log('findOneCity city = ', city)
          if (text_splitted.length >= 2 && city.num_department == text_splitted[1]) {
            r_city = city
            // console.log('findOneCity r_city = ', city)
          }
        })
        if (r_city == null && cities.length > 0) {
          r_city = cities[0]
          // console.log('findOneCity r_city = ', city)
        }
        resolve(r_city)
      })
      .catch(error => reject(error))
  })
}
