<template>
    <div class="user-form-dialog-wrapper container-fluid">
        <div class="row">
            <h1>Utilisateur</h1>
        </div>
        <div class="row">
            <FormInput
                id="user-form-input-login"
                v-model="model.login"
                type="text"
                name="login"
                label="Identifiant exploitant"
                placeholder="Identifiant exploitant"
            >
            </FormInput>
        </div>
        <div class="row">
            <FormInput
                id="user-form-input-mail"
                v-model="model.mail"
                type="text"
                name="mail"
                label="Mail"
                placeholder="Mail"
            >
            </FormInput>
        </div>

        <div v-if="editMode" class="row" style="margin-bottom: 30px;">
            <label>
                Changer de mot de passe
            </label>
            <input class="user-checkbox" type="checkbox" style="margin-left: 20px;" v-model="changePwd"/>
        </div>

        <div v-if="!editMode || changePwd" class="row">
            <FormInput
                id="user-form-input-pwd"
                v-model="model.pwd"
                type="password"
                name="pwd"
                label="Mot de passe"
                placeholder="Mot de passe"
            >
            </FormInput>
        </div>

        <div class="row">
            <FormInputSelect
                id="user-form-input-access"
                v-model="model.access"
                :items="items"
                label="Privilège"
                placeholder="Privilège"
                :item-text="x => x"
                style="width: 250px"
            >
            </FormInputSelect>
        </div>
        <div class="row" style="margin-top: 30px;">
            <label>
                Activé
            </label>
            <input class="user-checkbox" type="checkbox" style="margin-left: 20px;" v-model="model.activated"/>
        </div>
        <div class="row" style="justify-content: flex-end; margin-top: 30px;">
            <button class="btn btn-outline-secondary mr-2" @click="handleDismiss()"> Cancel </button>
            <button :disabled="saveDisabled" class="btn btn-secondary" @click="handleShare()">Save</button>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js' // only needed in custom components

// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import FormInput from '@/components/FormInput'
import FormInputSelect from '@/components/FormInputSelect'

import client from '@/api/premat/client'

export default {
  components: {
    FormInput,
    FormInputSelect
  },
  mixins: [VuejsDialogMixin],
  data () {
    return {
      items: [],
      initialModel: _.cloneDeep(this.options.data),
      model: _.cloneDeep(this.options.data),
      editMode: _.cloneDeep(this.options.editMode),
      changePwd: false
    }
  },
  watch: {
    changePwd () {
      if (!this.changePwd) {
        this.model.pwd = ''
      }
    }
  },
  computed: {
    saveDisabled () {
      return _.isEqual(this.model, this.initialModel)
                || !this.model.login
                || !this.model.mail
                || !this.model.access
    }
  },
  created () {
    client.get('/user/access/')
      .then(res => {
        this.items = res.data.accesses
      })
  },
  methods: {
    handleShare () {
      this.proceed(this.model)
    },
    handleDismiss () {
      this.cancel()
    }
  }
}
</script>

<style scoped>

.user-form-dialog-wrapper .container-fluid {
    top: 0px;
}

</style>
