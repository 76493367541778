import geojsonExtent from '@mapbox/geojson-extent'
import geojsonMerge from '@mapbox/geojson-merge'

export function dateStringToTimestamp (string, separator = '/') {
  return dateStringToDate(string, separator).getTime()
}

export function dateStringToDate (string, separator = '/') {
  let parts = string.split(separator)
  if (parts[2].length === 4) {
    parts = parts.reverse()
  }
  return new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]))
}

export function formatDate (date) {
  const parts = date.split('/')
  return parts.length === 3 ? `${parts[2]}/${parts[1]}/${parts[0]}` : '--'
}

export function parseDate (timestamp, inputName) {
  if (!timestamp) alert(`Le champ ${inputName} est obligatoire.`)
  const dateISO = new Date(timestamp).toISOString()
  const withoutTime = dateISO.split('T')[0]
  return withoutTime.replace(/-/g, '/')
}

export function formatWeight (authorization, separator = ',') {
  const tonne = authorization.tonne.toString()
  let kilogram = authorization.kilogram.toString()
  if (kilogram.length === 1) {
    kilogram = `00${kilogram}`
  } else if (kilogram.length === 2) {
    kilogram = `0${kilogram}`
  }
  return separator === ',' ? `${tonne},${kilogram}` : `${tonne}${kilogram}`
}

export function parseWeight (kg, alert = true) {
  if (!kg && alert) alert('Le champ poids est obligatoire.')
  kg = parseInt(kg)
  return {
    tonne: Math.floor(kg / 1000).toString(),
    kilogram: (kg % 1000).toString()
  }
}

export function formatDimensions (authorization) {
  return `${authorization.length} x ${authorization.width} x ${authorization.height}`
}

export function formatDimensionsEdit (authorization) {
  return `${authorization.length}x${authorization.width}x${authorization.height}`
}

export function parseDimensions (dimensions, alert = true) {
  if (!dimensions && alert) alert('Le champ dimensions est obligatoire.')
  const fields = dimensions.toLowerCase().split('x')
  if (fields.length !== 3 && alert) alert('Le champ dimensions doit être de la forme 00x00x00.')
  fields.forEach((field, index, array) => {
    array[index] = field.replace(/,/g, '.')
  })
  return {
    length: parseFloat(fields[0]).toString(),
    width: parseFloat(fields[1]).toString(),
    height: parseFloat(fields[2]).toString()
  }
}

export function chunkArray (array, size) {
  const result = []
  for (const value of array) {
    const lastArray = result[result.length - 1]
    if (!lastArray || lastArray.length === size) {
      result.push([value])
    } else {
      lastArray.push(value)
    }
  }
  return result
}

export function featureToStep (feature, authorization) {
  const department = featureDepartmentToDepartment(feature)

  return {
    id_authorization: authorization.id,
    num_step: 0,
    way: 0,
    latitude: feature.geometry.coordinates[1],
    longitude: feature.geometry.coordinates[0],
    num_department: department.num,
    page_number: null,
    comment: null,
    address: feature.properties.label,
    use_ors: null
  }
}

export function stepToGeoJson (step) {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [
        step.longitude,
        step.latitude
      ]
    },
    properties: { ...step }
  }
}

export function featureToStepGeoJson (
  feature,
  authorization,
  draggable = true,
  way = 0,
  existingStep = false
) {
  return geojsonExtent.bboxify({
    type: 'Feature',
    geometry: feature.geometry,
    properties: {
      draggable,
      id_authorization: authorization.id,
      num_step: null,
      way,
      latitude: feature.geometry.coordinates[1],
      longitude: feature.geometry.coordinates[0],
      name: existingStep ? existingStep.properties.name : null,
      page_number: existingStep ? existingStep.properties.page_number : null,
      comment: existingStep ? existingStep.properties.comment : null,
      address: feature.properties.label,
      use_ors: existingStep ? existingStep.properties.use_ors : 1
    }
  })
}

export function bboxToBounds (bbox) {
  return [
    [bbox[1], bbox[0]],
    [bbox[3], bbox[2]]
  ]
}

export function arrayOfStepFeaturesToFeatureCollection (stepFeatures = [], way) {
  const steps = []
  stepFeatures.forEach(step => {
    if (way === -1 || step.properties.way === way) {
      steps.push(step)
    }
  })
  return geojsonExtent.bboxify(geojsonMerge.merge(steps))
}

// This function is a weird hack to clone state.someValue to avoid mutating the
// store state outside of a mutation. Javascript does not have a native clone
// function, so we use this method instead.
// @see https://vuex.vuejs.org/guide/strict.html
export function clone (value) {
  return JSON.parse(JSON.stringify(value))
}

function featureDepartmentToDepartment (feature) {
  // HACK: ORS can return departements names witch are different of the ones in
  //       the back-end database
  // NOTE: The return from ORS seems to depend on the browser language
  if (feature.properties.region === 'Département du Rhône') {
    feature.properties.region = 'Rhône'
  } else if (feature.properties.region === 'Deux Sèvres') {
    feature.properties.region = 'Deux-Sèvres'
  } else if (feature.properties.region === 'Département de la Mayenne') {
    feature.properties.region = 'Mayenne'
  } else if (feature.properties.region === 'Cotes-d\'Armor') {
    feature.properties.region = 'Côtes-d\'Armor'
  } else if (feature.properties.region === 'Finistere') {
    feature.properties.region = 'Finistère'
  } else if (feature.properties.region === 'Ardenne') {
    feature.properties.region = 'Ardennes'
  } else if (feature.properties.region === 'Massif des Vosges') {
    feature.properties.region = 'Vosges'
  } else if (feature.properties.region === 'Département des Basses-Pyrénées') {
    feature.properties.region = 'Pyrénées-Atlantiques'
  }
  var departments = JSON.parse(localStorage.getItem('departments'))
  var department = departments.find(department => department.name === feature.properties.region)
  if (department === undefined) {
    throw new Error('Le département « ' + feature.properties.region + ' » n\'a pas été trouvé dans la liste des départements du back-end')
  }
  return department
}
