import axios from 'axios'
import {
  getAuthorization,
  getAuthorizationSteps,
  createAuthorizationSteps,
  deleteAuthorizationStep,
  updateAuthorization,
  updateAuthorizationRenewal,
  getDirections
} from '@/api/premat'
import { clone, stepToGeoJson, arrayOfStepFeaturesToFeatureCollection } from '@/utils'
import geojsonCoords from '@mapbox/geojson-coords'
import geojsonMerge from '@mapbox/geojson-merge'
// import geojsonExtent from '@mapbox/geojson-extent'

// This function is used to initialize or to reset the state of the module.
function initialState () {
  return {
    error: null,
    authorization: null,
    steps: [],
    featureCollectionWay0: arrayOfStepFeaturesToFeatureCollection(),
    featureCollectionWay1: arrayOfStepFeaturesToFeatureCollection(),
    lastStepAdded: null,
    directionWay0: null,
    directionWay1: null,
    directionDisplay: null
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations: {
    SET_ERROR: (state, error) => {
      state.error = error
      if (error != null) {
        if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined') {
          alert('ERREUR :\n' + error + '\n' + JSON.stringify(error.response.data))
        }
        // else {
        //   alert('ERREUR :\n' + error)
        // }
      }
    },
    SET_AUTHORIZATION: (state, value) => {
      state.authorization = value
      window.dispatchEvent(new Event('resize'))
    },
    SET_STEPS: (state, value) => {
      state.steps = value
      window.dispatchEvent(new Event('resize'))
    },
    SET_LAST_STEP_ADDED: (state, value) => {
      state.lastStepAdded = value
      window.dispatchEvent(new Event('resize'))
    },
    SET_FEATURE_COLLECTION_WAY_0: (state, value) => {
      state.featureCollectionWay0 = value
      window.dispatchEvent(new Event('resize'))
    },
    SET_FEATURE_COLLECTION_WAY_1: (state, value) => {
      state.featureCollectionWay1 = value
      window.dispatchEvent(new Event('resize'))
    },
    SET_directionWay0: (state, value) => {
      state.directionWay0 = value
      window.dispatchEvent(new Event('resize'))
    },
    SET_directionWay1: (state, value) => {
      state.directionWay1 = value
      window.dispatchEvent(new Event('resize'))
    },
    SET_directionDisplay: (state, value) => {
      state.directionDisplay = value
      window.dispatchEvent(new Event('resize'))
    },
    RESET_STATE: state => {
      Object.assign(state, initialState())
      window.dispatchEvent(new Event('resize'))
    }
  },
  actions: {
    resetState ({ commit }) {
      commit('RESET_STATE')
    },
    getAuthorization ({ commit }, authorizationId) {
      return new Promise((resolve, reject) => {
        commit('SET_ERROR', null)
        getAuthorization(authorizationId)
          .then(response => {
            commit('SET_AUTHORIZATION', response.data.authorization)
            resolve(response)
          })
          .catch(error => {
            commit('SET_ERROR', error)
            reject(error)
          })
      })
    },
    validateAuthorization ({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_ERROR', null)
        updateAuthorization(state.authorization.id, { status: 'validated' })
          .then(response => {
            commit('SET_AUTHORIZATION', response.data.authorization)
            resolve(response)
          })
          .catch(error => {
            commit('SET_ERROR', error)
            reject(error)
          })
      })
    },
    submitAuthorizationForValidation ({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_ERROR', null)
        updateAuthorization(state.authorization.id, { status: 'waiting for validation' })
          .then(response => {
            commit('SET_AUTHORIZATION', response.data.authorization)
            resolve(response)
          })
          .catch(error => {
            commit('SET_ERROR', error)
            reject(error)
          })
      })
    },
    updateAuthorizationRenewal ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_ERROR', null)
        updateAuthorizationRenewal(state.authorization.id, data)
          .then(response => {
            commit('SET_AUTHORIZATION', response.data.authorization)
            resolve(response)
          })
          .catch(error => {
            commit('SET_ERROR', error)
            reject(error)
          })
      })
    },
    getSteps ({ commit }, authorizationId) {
      return new Promise((resolve, reject) => {
        commit('SET_ERROR', null)
        return getAuthorizationSteps(authorizationId)
          .then(response => {
            const steps = []
            response.data.steps.forEach(step => {
              steps.push(stepToGeoJson(step))
            })
            commit('SET_STEPS', steps)
            commit('SET_FEATURE_COLLECTION_WAY_0', arrayOfStepFeaturesToFeatureCollection(steps, 0))
            commit('SET_FEATURE_COLLECTION_WAY_1', arrayOfStepFeaturesToFeatureCollection(steps, 1))
            resolve(response)
          })
          .catch(error => {
            commit('SET_ERROR', error)
            reject(error)
          })
      })
    },
    addStep ({ commit, state }, payload) {
      // the payload variable is an object like { step, index }
      const steps = clone(state.steps)
      payload.step.properties.num_step = payload.index + 1
      steps.splice(payload.index, 0, payload.step)
      // change num_step according to index
      steps.forEach((current, index) => {
        current.properties.num_step = index + 1
      })
      commit('SET_STEPS', steps)
      commit('SET_FEATURE_COLLECTION_WAY_0', arrayOfStepFeaturesToFeatureCollection(steps, 0))
      commit('SET_FEATURE_COLLECTION_WAY_1', arrayOfStepFeaturesToFeatureCollection(steps, 1))
      commit('SET_LAST_STEP_ADDED', steps[payload.index])
    },
    updateStep ({ commit, state }, step) {
      const steps = clone(state.steps)
      steps.forEach((current, index, all) => {
        if (current.properties.num_step === step.properties.num_step
          && current.properties.way === step.properties.way) {
          all[index] = step
        }
      })
      commit('SET_STEPS', steps)
      commit('SET_FEATURE_COLLECTION_WAY_0', arrayOfStepFeaturesToFeatureCollection(steps, 0))
      commit('SET_FEATURE_COLLECTION_WAY_1', arrayOfStepFeaturesToFeatureCollection(steps, 1))
      commit('SET_LAST_STEP_ADDED', step)
    },
    updateStepUseORS ({ commit, state }, use_ors_and_num_step) {
      const steps = clone(state.steps)
      const num_step = (use_ors_and_num_step[1] == -1
        ? steps.length - 1 : use_ors_and_num_step[1]) - 1
      steps[num_step].properties.use_ors = use_ors_and_num_step[0] ? 1 : 0
      commit('SET_STEPS', steps)
      commit('SET_FEATURE_COLLECTION_WAY_0', arrayOfStepFeaturesToFeatureCollection(steps, 0))
      commit('SET_FEATURE_COLLECTION_WAY_1', arrayOfStepFeaturesToFeatureCollection(steps, 1))
      commit('SET_LAST_STEP_ADDED', steps[steps.length - 1])
    },
    removeStep ({ commit, state, dispatch }, step) {
      return new Promise(resolve => {
        // remove the step from array
        const steps = clone(state.steps).filter(current => {
          return current.properties.num_step !== step.properties.num_step
        })
        // reindex num_step for all existing steps
        steps.forEach((current, index, all) => {
          all[index].properties.num_step = index + 1
        })
        commit('SET_STEPS', steps)
        commit('SET_FEATURE_COLLECTION_WAY_0', arrayOfStepFeaturesToFeatureCollection(steps, 0))
        commit('SET_FEATURE_COLLECTION_WAY_1', arrayOfStepFeaturesToFeatureCollection(steps, 1))
        resolve(step)
      })
    },
    saveSteps ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_ERROR', null)
        // we first get all the authorization steps from database
        getAuthorizationSteps(state.authorization.id).then(response => {
          const stepsToCreate = []
          state.steps.forEach(item => {
            stepsToCreate.push(item.properties)
          })
          const authorizationId = state.authorization.id
          // we delete and re-create all steps
          createAuthorizationSteps(authorizationId, stepsToCreate).then(response1 => {
            // we update authorization geojson for way 0
            updateAuthorization(authorizationId, { geojson_way_0: JSON.stringify(state.directionWay0) }).then(response2 => {
              // we update authorization geojson for way 1
              updateAuthorization(authorizationId, { geojson_way_1: JSON.stringify(state.directionWay1) }).then(response3 => {
                // once all steps are created, we get a fresh list of steps from
                // the database
                dispatch('getSteps', authorizationId)
                dispatch('clearLastStepAdded')
                resolve(response3)
              }).catch(error => {
                commit('SET_ERROR', error)
                reject(error)
              })
              resolve(response2)
            }).catch(error => {
              commit('SET_ERROR', error)
              reject(error)
            })
            resolve(response1)
          }).catch(error => {
            commit('SET_ERROR', error)
            reject(error)
          })
        }).catch(error => {
          commit('SET_ERROR', error)
          reject(error)
        })
      })
    },
    clearLastStepAdded ({ commit }) {
      commit('SET_LAST_STEP_ADDED', null)
    },
    getDirection ({ commit, state }, showReturnWay = true) {
      if (state.featureCollectionWay0.features.length <= 1) {
        // there is 0 or 1 step, we reset the direction
        commit('SET_directionWay0', arrayOfStepFeaturesToFeatureCollection())
        commit('SET_directionDisplay', arrayOfStepFeaturesToFeatureCollection())
      } else {
        const featureCollectionWay0 = clone(state.featureCollectionWay0)
        const coordinatesWay0 = geojsonCoords(featureCollectionWay0)
        const useORSWay0 = []
        const useORSWay1 = []
        clone(state.steps).forEach(step => {
          if (step.properties.way == 0) {
            useORSWay0.push(step.properties.use_ors)
          } else if (step.properties.way == 1) {
            useORSWay1.push(step.properties.use_ors)
          }
        })
        getDirections(state.authorization.id, 0, coordinatesWay0, useORSWay0)
          .then(response0 => {
            const geojsonWay0 = geojsonMerge.merge([response0.data])
            commit('SET_directionWay0', geojsonWay0)
            commit('SET_directionDisplay', geojsonWay0)
            if (state.featureCollectionWay1.features.length <= 1) {
              commit('SET_directionWay1', arrayOfStepFeaturesToFeatureCollection())
            } else {
              const featureCollectionWay1 = clone(state.featureCollectionWay1)
              const coordinatesWay1 = geojsonCoords(featureCollectionWay1)
              getDirections(state.authorization.id, 1, coordinatesWay1, useORSWay1)
                .then(response1 => {
                  const geojsonWay1 = geojsonMerge.merge([response1.data])
                  commit('SET_directionWay1', geojsonWay1)
                  commit('SET_directionDisplay', geojsonMerge.merge([response0.data, response1.data]))
                }).catch(error => {
                  console.log('ERROR authorization.js - error = ', error)
                  commit('SET_ERROR', error)
                })
            }
          }).catch(error => {
            commit('SET_ERROR', error)
          })
      }
    },
    modifyStepDetails ({ commit, state }, payload) {
      // the payload variable is an object like { name, value, step }
      const steps = clone(state.steps)
      steps.forEach((current, index, all) => {
        if (current.properties.num_step === payload.step.properties.num_step
          && current.properties.way === payload.step.properties.way) {
          all[index].properties[payload.name] = payload.value
        }
      })
      commit('SET_STEPS', steps)
      commit('SET_FEATURE_COLLECTION_WAY_0', arrayOfStepFeaturesToFeatureCollection(steps, 0))
      commit('SET_FEATURE_COLLECTION_WAY_1', arrayOfStepFeaturesToFeatureCollection(steps, 1))
    }
  }
}
