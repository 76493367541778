import { login, logout, getUser } from '@/api/premat'

// 3 roles for premat users:
// limited: routiers
// manager: exploitants with authorization validation capability
// admin: agenium scale employee

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('user')),
    token: localStorage.getItem('token'),
    filter_city_start: null,
    filter_city_end: null,
    filter_tonnage: null,
    search: null,
    columns: null
  },
  getters: {
    isLoggedIn: state => state.user !== null && state.token !== null,
    username: state => state.user ? state.user.user.login : null
  },
  mutations: {
    SET_LOADING: (state, value) => {
      state.loading = value
    },
    SET_USER: (state, value) => {
      state.user = value
    },
    SET_TOKEN: (state, value) => {
      state.token = value
    },
    SET_FILTERS: (state, value) => {
      state.filter_city_start = value[0]
      state.filter_city_end = value[1]
      state.filter_tonnage = value[2]
    },
    SET_SEARCH: (state, value) => {
      state.search = value
    },
    SET_COLUMNS: (state, value) => {
      state.columns = value
    },
    SET_ERROR: (state, error) => {
      state.loading = false
      state.error = error
    }
  },
  actions: {
    getUser ({ commit }, id) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      return new Promise((resolve, reject) => {
        getUser(id)
          .then(response => {
            commit('SET_LOADING', false)
            localStorage.setItem('user', JSON.stringify(response.data))
            commit('SET_USER', response.data)
          })
          .catch(error => {
            commit('SET_LOADING', false)
            commit('SET_ERROR', error)
            reject(error)
          })
      })
    },
    login ({ commit, dispatch }, form) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      return new Promise((resolve, reject) => {
        login(form.username, form.password)
          .then(response => {
            commit('SET_LOADING', false)
            commit('SET_TOKEN', response.data.token)
            localStorage.setItem('token', response.data.token)
            dispatch('getUser', response.data.id)
            resolve(response)
          })
          .catch(error => {
            commit('SET_LOADING', false)
            commit('SET_TOKEN', null)
            commit('SET_USER', null)
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            if (error.response) {
              commit('SET_ERROR', error.response.data.message)
            } else {
              commit('SET_ERROR', 'Le backend de l\'application n\'est pas en service, veuillez vous rapprocher de l\'administrateur de l\'application')
            }
            reject(error)
          })
      })
    },
    logout ({ commit }) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      return new Promise((resolve, reject) => {
        logout()
          .then(response => {
            commit('SET_LOADING', false)
            commit('SET_TOKEN', null)
            commit('SET_USER', null)
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('departments')
            resolve(response)
          })
          .catch(error => {
            commit('SET_LOADING', false)
            commit('SET_TOKEN', null)
            commit('SET_USER', null)
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('departments')
            reject(error)
          })
      })
    }
  }
}
