import axios from 'axios'
import qs from 'qs'
import router from '@/router'

const client = axios.create({
  baseURL: process.env.VUE_APP_PREMAT_API_URL,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  timeout: 30000
})

// request interceptor to manage the user authentication
client.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = localStorage.getItem('token')
  }
  return config
}, error => Promise.reject(error))

// request interceptor to manage the request body
client.interceptors.request.use(config => {
  if (config.method === 'post' || config.method === 'put') {
    if (config.headers['X-No-Transform'] === '1') {
      // we created this custom header because the api does not allow fields
      // with array index (these indexes are created if we pass data to
      // qs.parse and qs.stringify). If this header is present, we return
      // config as is.
      delete config.headers['X-No-Transform']
      return config
    }
    if (config.data instanceof FormData) {
      // we do not want to transform FormData
      return config
    }
    let body = config.data || {}
    if (config.headers['Content-Type'] !== 'application/x-www-form-urlencoded') {
      body = qs.parse(config.data)
    }
    const data = {}
    for (const key in body) {
      if (Object.prototype.hasOwnProperty.call(body, key)) {
        // we make sure there is no data key with an empty value
        if (body[key] !== null && body[key] !== '') {
          data[key] = body[key]
        }
      }
    }
    config.data = qs.stringify(data)
  }
  return config
}, error => Promise.reject(error))

// response interceptor to manage the user authentication
client.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response && error.response.status && error.response.status === 401) {
    // if the backend api returns a 401, we redirect the user to the login page
    router.push({ name: 'login' })
  } else {
    return Promise.reject(error)
  }
})

export default client
